
        <template>
          <div class="vc-snippet-doc">
            <h2>快速上手</h2>
<p>本节将介绍如何在项目中使用组件库。</p>
<h3>引入 ixu-ui</h3>
<p>你可以引入整个 ixu-ui，或是根据需要仅引入部分组件。我们先介绍如何引入完整的 ixu-ui。</p>
<h4>完整引入</h4>
<p>使用私有NPM仓库</p>
<pre class="hljs"><code><span class="hljs-meta">#</span><span class="bash"> npm</span>
npm set registry http://112.29.174.227:4873
<span class="hljs-meta">
#</span><span class="bash"> yarn</span>
yarn set registry http://112.29.174.227:4873
</code></pre>
<p>在 main.js 中写入以下内容：</p>
<pre class="hljs"><code><span class="hljs-keyword">import</span> Vue <span class="hljs-keyword">from</span> <span class="hljs-string">'vue'</span>;
<span class="hljs-keyword">import</span> IxuUI <span class="hljs-keyword">from</span> <span class="hljs-string">'ixu-ui'</span>
<span class="hljs-keyword">import</span> <span class="hljs-string">'ixu-ui/lib/ixu.css'</span>
<span class="hljs-keyword">import</span> App <span class="hljs-keyword">from</span> <span class="hljs-string">'./App.vue'</span>;

Vue.use(IxuUI);

<span class="hljs-keyword">new</span> Vue({
  <span class="hljs-attr">el</span>: <span class="hljs-string">'#app'</span>,
  <span class="hljs-attr">render</span>: <span class="hljs-function"><span class="hljs-params">h</span> =&gt;</span> h(App)
});
</code></pre>
<p>注意：样式文件需要单独引入。</p>
<h4>按需引入</h4>
<p>开发中...</p>
<!-- 借助 [babel-plugin-component](https://github.com/QingWei-Li/babel-plugin-component)，我们可以只引入需要的组件，以达到减小项目体积的目的。

首先，安装 babel-plugin-component：

```bash
npm install babel-plugin-component -D
```

然后，将 .babelrc 修改为：

```json
{
  "presets": [["es2015", { "modules": false }]],
  "plugins": [
    [
      "component",
      {
        "libraryName": "jy-required-import",
        "styleLibraryName": "theme"
      }
    ]
  ]
}
```

接下来，如果你只希望引入部分组件，比如 只需要引入素材通用模块中的Content（latex转义html解析），那么需要在 main.js 中写入以下内容：

```javascript
import Vue from 'vue';
import { Input } from 'jy-required-import';
import App from './App.vue';

Vue.component(Input.name, Input);
/* 或写为
 * Vue.use(Input)
 */

new Vue({
  el: '#app',
  render: h => h(App)
});
``` -->

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            
           }
         }
       </script>
       <style lang='scss'>
         
       </style>